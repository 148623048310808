/*!
 *  Stunnplate script.js
 *  Version: 1.3.2
 *
 */
/*
*
*  Notes:
*  Main JS file to include entire library followed by custom scripts.
*
*
*/

/*-------------------------------------------------------*/
/*- SITE SPECIFIC SCRIPT                                -*/
/*-------------------------------------------------------*/

//This is no longer the place for page specific stuff. make sure you use _pagespecific.js for that! (040117 MG)


/********************************************************************/






/***************** Main menu *******************/

// alert(34);

$(window).on('load resize', menuHover);
function menuHover(){

    $('li.first-level-submenu-parent').unbind('mouseenter mouseleave');
    if ($(window).width() > 768) {

        $('li.first-level-submenu-parent').hover(function () {
            console.log(34)
            clearTimeout($.data(this,'timer'));
            $('ul.first-level-submenu',this).stop(true,true).slideDown(200);
            // $( this ).css('background-color', '#f8f9f7');
            // $( "a.submenu-sibling", this ).css('color', '#0086bf');
        }, function () {
            $.data(this,'timer', setTimeout($.proxy(function() {
                $('ul.first-level-submenu',this).stop(true,true).slideUp(200);
                $( this ).removeAttr( 'style' );
                $( "a.submenu-sibling", this ).removeAttr( 'style' );
            }, this), 100));
        });
    }
}


$(document).ready(function($) {


});

$('.form-field input').on("keyup change", function() {

    if(!$(this).is(':valid')){
        $(this).parent().addClass("validation-error");
    }
    else
        $(this).parent().removeClass("validation-error");
});

function playPauseVideoSection7() {

    var video = document.getElementById("video-home-section-7");
    // alert(video)

    // $('#video-home-section-7').get(0).play();
    if (video.paused) {
        video.play();
        // btn.innerHTML = "Pause";
    } else {
        video.pause();
        //btn.innerHTML = "Play";
    }
}

// function showHideRegistrationForm(){
//     $("#banner-employee-registration-form").css("width", "370");
//     $("#banner-employee-registration-form").toggle(800);
//     $("#banner-employee-registration-form").css("width", "370");
// }


function showHideRegistrationForm(){

    $("#banner-employee-registration-form").css("min-width", $("#container-watch-download-form").width());

    $("#banner-employee-video-wrap").hide(800);
    $("#banner-employee-registration-form").toggle(800);


}


function showHideVideoWrap() {

    $("#banner-employee-video-wrap").css("min-width", $("#container-watch-download-form").width());

    $("#banner-employee-registration-form").hide(800);
    $("#banner-employee-video-wrap").toggle(800);
}



function closeMobileMenu() {
    if ($(window).width() > 768) {
        $("#main-nav-wrap, #page-container").removeClass("menu-opened");
        $(".submenu-opened").removeClass("submenu-opened");
        $(".submenu").removeAttr( 'style' );
        $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
        $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
    }
}

function toggleMobileMenu(){

    $('#main-menu').toggleClass("is-opened");
    $('#page-container, #menu-button').toggleClass("menu-is-opened");

    //$("#main-nav-wrap, #page-container").toggleClass("menu-opened");
    $(".submenu-opened").removeClass("submenu-opened");
    $(".submenu").removeAttr( 'style' );
    $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
    $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
}

function openSubmenu(e) {


    if ($(window).width() < 768) {


        if(!$(this).hasClass('submenu-opened') ){

            $(this).find(".submenu").slideDown();
        }

        else{
            $(this).find(".submenu:not(\".menu-opened-second-level\")").slideUp();
        }

        $(this).toggleClass("submenu-opened");

        //$(this).siblings(".submenu").toggleClass("submenu-opened").slideToggle();
        $(this).find(".submenu").toggleClass("submenu-opened");
        // $(this).find(".submenu").toggle();
        //$(this).find(".submenu").show();

        return false;
    }
}
function openSubmenu2(e) {
    $(this).parent().toggleClass("menu-opened-second-level");
}


function openSubmenu3(e) {
    // e.preventDefault();
    e.stopPropagation();
}

$("#mobile-nav-close").click(function() {
    $("#main-nav-wrap, #page-container").removeClass("menu-opened");
    $(".submenu-opened").removeClass("submenu-opened");
    $(".submenu").removeAttr( 'style' );
    $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
    $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
});
//
// $("#mobile-menu-button").on("click", toggleMobileMenu);
$("#menu-button").on("click", toggleMobileMenu);

$(".second-level-submenu-parent").click(openSubmenu2);
// $(".submenu-opened .aberto").click( openSubmenu2);
$(".first-level-submenu-parent").click(openSubmenu);

$(".second-level-submenu a").click( openSubmenu3);
// $(".submenu-opened .second-level-submenu  a").click( function(e) {alert(e)});
$(window).on('resize', closeMobileMenu);

//
$('.hidden-box-sibling').click(function(e) {
    e.preventDefault();
    var $boxSibling = $(".hidden-box-sibling");
    $boxSibling.not(this).removeClass("hidden-box-opened");
    $(this).addClass("hidden-box-opened");
    $(".main-nav-search, .main-nav-login").removeClass("hidden-box-opened");

    if ($(window).width() > 991) {
        $boxSibling.not(this).siblings('.hidden-box').removeClass("hidden-box-opened").hide("fast");
        $(this).siblings(".hidden-box").addClass("hidden-box-opened").show('fast');
    }

    if ($(window).width() < 992) {
        $boxSibling.not(this).siblings('.hidden-box').removeClass("hidden-box-opened");
        $(this).siblings(".hidden-box").addClass("hidden-box-opened");
        $(this).parent(".main-nav-search, .main-nav-login").addClass("hidden-box-opened");
    }
});

$(document).click(function(e) {
    if ($(e.target).closest(".hidden-box, .hidden-box-sibling").length) {
        return;
    }
    if ($(window).width() > 991) {
        $(".hidden-box").removeClass("hidden-box-opened").hide("fast");
        $('.hidden-box-sibling').removeClass("hidden-box-opened");
        e.stopPropagation();
    }
});