/*
    This Dom Ready Util is a way of loading page specific scripts based on the classes and ID of the page.
    From Paul Irish: https://www.paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/
    See also _pagespecific.js which defines PAGESCRIPT
 */

// Use this function to camelCase class names so we can use our this-that naming convention
function camelCase(dashCase) {
    return dashCase.replace(/-([a-z])/gi, function ($0, $1) {
        return $1.toUpperCase();
    });
};

DOMREADYUTIL = {

    fire: function (func, funcname, args) {

        var namespace = PAGESCRIPT;  // indicate your obj literal namespace here

        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function') {
            namespace[func][funcname](args);
        }

    },

    loadEvents: function () {

        var bodyId = document.body.id;

        // hit up common first.
        DOMREADYUTIL.fire('common');

        // do all the classes too.
        $.each(document.body.className.split(/\s+/), function (i, classnm) {
            DOMREADYUTIL.fire(camelCase(classnm));
            DOMREADYUTIL.fire(camelCase(classnm), camelCase(bodyId));
        });

        DOMREADYUTIL.fire('common', 'finalize');

    }

};

// kick it all off here 
$(document).ready(DOMREADYUTIL.loadEvents);