/*
	Define page specific scripts here which will be loaded by _domreadyutil.js based on the ID and class of the body tag 
	of any page
 */

// The utility converts dashed classnames to camelCase as we can't use dashes in our variables here so resourcesPage = resources-page

PAGESCRIPT = {
    common: {
        init: function () {
            sniffbrowser();

            // This is where you can put GLOBAL dom ready scripts

            $('#menu-button').on('click', function (e) {
                e.preventDefault();

                // $('#main-menu').toggleClass("is-opened");
                // $('#page-container, #menu-button').toggleClass("menu-is-opened");
            });


            $(function () {
                $('.bookmarkme').click(function (e) {
                    e.preventDefault();

                    if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
                        window.sidebar.addPanel(document.title, window.location.href, '');
                    } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
                        window.external.AddFavorite(location.href, document.title);
                    } else if (window.opera && window.print) { // Opera Hotlist
                        this.title = document.title;
                        return true;
                    } else { // webkit - safari/chrome
                        alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
                    }
                });
            });

            // Smooth Scrolling

            $('a[href*="#"]').not('[href="#"]').not('[href*="#modal"]').click(function (event) {

                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {

                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                    if (target.length) {
                        event.preventDefault();

                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 750);
                    }
                }
            });

        },
        finalize: function () {

        }
    },
    contactPage: {
        init: function () {
            // This is an example of where to put PAGE SPECIFIC dom ready scripts. The script automaticall converts to camelCase for example, .contact-page becomes contactPage here

        }
    }
};